@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content-div{
  background-image:url('https://images.unsplash.com/photo-1522093007474-d86e9bf7ba6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80');
  background-repeat: no-repeat;
  background-size: cover;
  background-position:center;
}
  .content-div:hover{
    background-image:
    linear-gradient(to right,
     rgba(126, 213, 111, 0.801), hsla(160, 64%, 43%, 0.801) ),
     url('https://images.unsplash.com/photo-1522093007474-d86e9bf7ba6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80');
    }

    .image-cover  {
      height:260px;
    }
  /*
  -remove the classes below if you have the 'group-hover'property added in your tailwind config file
  -also remove the class from the html template
  */
    .content-div:hover .fd-cl{ 
 opacity: 0.25;
    }
    .content-div:hover .fd-sh{ 
      opacity: 1;
         }
     
/*
whatsAPP
  */

  .btn-whatsapp {
    display:block;
    width:70px;
    height:70px;
    color:#ffff;
    position: fixed;
    right:20px;
    bottom:20px;
    border-radius:50%;
    line-height:80px;
    text-align:center;
    z-index:999;
}


.azul {
  background-color: #002c54;
}

.blancoicono {
  color: #FFFFFF;
}

.verde {
  background-color: #8cac38;
}

.verdeicono {
  color:#8cac38;
}



.azulclaro {
  background-color: #929cb4;
}

.verdeclaro {
  background-color: #cddaa7;
}

.semititulos {
  font-size: calc(5px + 2vmin);
  font-family: 'Nunito', sans-serif;
  font-weight: 800;
  color: #002c54;
}

.semititulosblanco {
  font-size: calc(5px + 2vmin);
  font-family: 'Nunito', sans-serif;
  font-weight: 800;
  color: #FFFFFF;
}

.semititulosverde {
  font-size: calc(5px + 2vmin);
  font-family: 'Nunito', sans-serif;
  font-weight: 800;
  color: #8cac38;
}

.titulos {
  font-size: calc(25px + 2vmin);

  font-family: 'Nunito', sans-serif;
  color: #002c54;
  font-weight: 800;

}

.titulosverde {
  font-size: calc(25px + 2vmin);

  font-family: 'Nunito', sans-serif;
  color: #8cac38;
  font-weight: 800;

}

.titulosblanco {
  font-size: calc(25px + 2vmin);

  font-family: 'Nunito', sans-serif;
  color: #FFFFFF;
  font-weight: 800;

}

.titulosblanconosize {

  font-family: 'Nunito', sans-serif;
  color: #FFFFFF;
  font-weight: 800;

}

.parrafo {
  font-family: 'Nunito', sans-serif;
font-weight: 600;
}

.parrafoverde {
  font-family: 'Nunito', sans-serif;
font-weight: 600;
color: #8cac38;

}

.hover-trigger .hover-target {
  display: none;
}

.hover-trigger:hover .hover-target {
  display: block;
}

